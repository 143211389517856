import React from 'react'
import { Link } from 'react-router-dom'
import { withAuth } from 'state/auth.js'

import { Icon } from '@hsq/razzle-dazzle'

const SideNav = () => {
  return (
    <nav className="sidebar hub-sidebar-nav border-0 p-0 bg-primary900">
      <ul className="nav nav-pills flex-column">
        <li className="nav-item border-top-0">
          <Link className="nav-link bg-primary900" to="/">
            <h2>
              <Icon className="text-white" id="home" />
            </h2>
          </Link>
        </li>
        <li className="nav-item border-bottom-0">
          <Link className="nav-link bg-primary900" to="/help">
            <h2>
              <Icon className="text-white" id="help" />
            </h2>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default withAuth(SideNav)
