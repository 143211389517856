import { hot } from 'react-hot-loader/root'
import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Provider } from 'unstated'

import NewLoginScreen from 'component/LoginScreen/LoginScreenContainer.jsx'
import LogoutScreenv2 from 'component/LogoutScreenv2.jsx'
import IndexScreenv2 from '../IndexScreen/IndexScreenv2.jsx'
import DocsScreen from '../DocsScreen/DocsScreen.js'
import SwaggerDisplay from '../DocsScreen/SwaggerDisplay.js'
import Footer from './Footer.js'
import DocDisplay from '../DocsScreen/DocDisplay.js'
import ForbiddenScreen from '../ForbiddenScreen.js'
import FirstTimeLoginScreen from 'component/FirstTimeLoginScreen/FirstTimeLoginScreen.jsx'
import UserManagerScreen from 'component/UserManagerScreen'
import KeyManagementScreen from 'component/KeyManagementScreen'
import ForgotPassword from 'component/LoginScreen/ForgotPassword/ForgotPassword.jsx'

import { component } from './App.module.scss'
import Header from './Header.jsx'
import Notifications from './Notifications'

class App extends Component {
  render() {
    return (
      <div className={`${component} App scoped-hippo-styles`}>
        <Router>
          <Provider>
            <Header />
            <Route path="/" exact component={IndexScreenv2} />
            <Route path="/forbidden" exact component={ForbiddenScreen} />
            <Route path="/token-manager" exact component={KeyManagementScreen} />
            <Route path="/login" exact component={NewLoginScreen} />
            <Route path="/user-manager" exact component={UserManagerScreen} />
            <Route path="/activate" exact component={FirstTimeLoginScreen} />
            <Route path="/reset-password" exact component={FirstTimeLoginScreen} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/docs" exact component={DocsScreen} />
            <Route path="/docs/:id/:hostedAt/*" component={DocDisplay} />
            <Route path="/swagger/:id/:hostedAt/*" component={SwaggerDisplay} />
            <Route path="/logout" exact component={LogoutScreenv2} />
            <Notifications />
            <div className="footer-padding-handler" />
            <Footer />
          </Provider>
        </Router>
      </div>
    )
  }
}

export default hot(App)
