import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import loginApi from 'api/login.js'
import { withAuth } from '../state/auth.js'

class LogoutScreenv2 extends Component {
  componentDidMount = () => {
    loginApi.logout().then(() => this.props.history.push('/'))
  }
  render = () => <div />
}

export default withAuth(withRouter(LogoutScreenv2))
