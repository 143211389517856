import safeFetch from './safe-fetch'

/* eslint-disable prefer-destructuring */
// const NODE_ENV = process.env.NODE_ENV
// const USE_GATEWAY = process.env.USE_GATEWAY

/**
 * The API class abstracts application business logic for performing API requests.
 */
class Api {
  /**
   * The host that is prepended to all API requests to hit the correct AC Gateway.

  /**
   * Handles prepending the apiBase to all API requests.
   *
   * ⚠️ If you need to make a request to an endpoint other than the gateway, use the
   * `safeFetch` utility (or plain fetch) directly.
   * @param {string} url
   * @param {Object} args
   */
  fetch(url, ...args) {
    return safeFetch(url, ...args)
  }
}

// Export a singleton instance
export default new Api()
