import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { shape } from 'prop-types'
import classNames from 'classnames'
import { Alert, Button, Icon } from '@hsq/razzle-dazzle'
import { FormProvider, SchemaNode } from '@hsq/formogorgon'

import loginApi from 'api/login'

import { component } from './FirstTimeLoginScreen.module.scss'

class FirstTimeLoginScreen extends Component {
  static propTypes = {
    history: shape().isRequired,
    location: shape().isRequired,
  }

  state = {
    error: '',
    passwordError: '',
    passwordRules: {},
    tempPasswordValidity: '',
  }

  componentDidMount() {
    loginApi.getLoginParameters().then(({ data, error }) => {
      if (data) this.setState({ passwordRules: data })
      if (error) this.setState({ passwordError: error })
    })
  }

  componentDidUpdate(_, prevState) {
    const { passwordRules: prevPasswordRules } = prevState
    const { passwordRules } = this.state
    const { temporaryPasswordValidityDays } = passwordRules
    if (JSON.stringify(prevPasswordRules) !== JSON.stringify(passwordRules)) {
      this.setState({
        tempPasswordValidity: temporaryPasswordValidityDays,
      })
    }
  }

  firstLogin = ({ username, tempPassword, newPassword }) => {
    const { history } = this.props
    loginApi
      .firstTimeLogin(username, tempPassword, newPassword)
      .then(({ response, error }) => {
        if (response && response.ok) history.push('/login')
        else this.setState({ error: `Problem with authentication: ${error}` })
      })
  }

  confirmPassword = ({ username, tempPassword, newPassword }) => {
    const { history } = this.props
    loginApi
      .confirmPassword(username, tempPassword, newPassword)
      .then(({ response, error }) => {
        if (response && response.ok) history.push('/login')
        else this.setState({ error: `Problem with authentication: ${error}` })
      })
  }

  render() {
    const { location } = this.props
    const { error, passwordRules, passwordError, tempPasswordValidity } = this.state

    const passwordCheck = [
      {
        toggled: !!passwordRules.minimumLength,
        description: `Password must be a minimum of ${
          passwordRules.minimumLength
        } characters`,
        match: `^.{${passwordRules.minimumLength},}$`,
      },
      {
        toggled: !!passwordRules.requireUppercase,
        description: 'Must include one uppercase character',
        match: '[A-Z]',
      },
      {
        toggled: !!passwordRules.requireLowercase,
        description: 'Must include one lowercase character',
        match: '[a-z]',
      },
      {
        toggled: !!passwordRules.requireNumber,
        description: 'Must include one number',
        match: '[0-9]',
      },
      {
        toggled: !!passwordRules.requireSymbols,
        description: 'Must include a symbol',
        match: '[^a-zA-Z0-9]',
      },
    ]

    const activate = location.pathname.includes('activate')

    const newPasswordText = value => (
      <>
        {!passwordError && (
          <ul className={classNames({ 'list-unstyled': value })}>
            {passwordCheck.map(
              ({ toggled, description, match }) =>
                toggled && (
                  <li key={description}>
                    {value && (
                      <Icon
                        className={`${
                          value.match(match) ? 'text-success' : 'text-danger'
                        } mr-1`}
                        id={
                          value.match(match)
                            ? 'check-circle-fill'
                            : 'close-circle-fill'
                        }
                      />
                    )}
                    {description}
                  </li>
                ),
            )}
          </ul>
        )}
        {passwordError && <Alert>{passwordError}</Alert>}
      </>
    )

    const tempPasswordMessage = tempPasswordValidity
      ? `This temporary password will expire in ${
          passwordRules.temporaryPasswordValidityDays
        } days.`
      : ''

    const formSchema = {
      type: 'object',
      properties: {
        tempPassword: {
          type: 'string',
          title: activate
            ? 'Enter your temporary password'
            : 'Enter your password reset code',
          description: activate
            ? `We've sent the temporary password to the email provided. ${tempPasswordMessage}`
            : `We've sent the reset code to the email provided. ${tempPasswordMessage}`,
        },
        username: {
          type: 'string',
          title: 'Enter your email address',
        },
        newPassword: {
          typeOverride: 'password',
          type: 'string',
          title: 'Enter your desired password',
          pattern: '^(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
        },
        confirmPassword: {
          typeOverride: 'password',
          type: 'string',
          title: 'Confirm new password',
          validationMessage: 'Passwords do not match',
        },
      },
      required: ['tempPassword', 'username', 'newPassword', 'confirmPassword'],
    }

    return (
      <div className={`${component} w-100`}>
        <div className="title py-6 mb-4 d-flex justify-content-center">
          <div className="col-6">
            <h1 className="display-3">
              {activate ? 'Welcome to DevHub' : 'Reset your password'}
            </h1>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-start col-6 mx-auto mt-5">
          <FormProvider
            formId="firstLogin"
            defaultSchema={formSchema}
            className="w-100"
            onSubmit={activate ? this.firstLogin : this.confirmPassword}
            version={JSON.stringify(formSchema)}
          >
            {({ formInvalid, valueById, invalidById }) => (
              <>
                <SchemaNode materializedPath="firstLogin,tempPassword" invalid="" />
                <SchemaNode materializedPath="firstLogin,username" invalid="" />
                <SchemaNode materializedPath="firstLogin,newPassword" invalid="" />
                {newPasswordText(valueById['firstLogin,newPassword'])}
                <SchemaNode
                  materializedPath="firstLogin,confirmPassword"
                  invalid={
                    !!valueById['firstLogin,confirmPassword'] &&
                    (invalidById['firstLogin,newPassword'] ||
                      valueById['firstLogin,newPassword'] !==
                        valueById['firstLogin,confirmPassword'])
                      ? // This text doesn't matter because it will use the validation message from the json schema
                        'true'
                      : ''
                  }
                  decoration={
                    !invalidById['firstLogin,newPassword'] &&
                    valueById['firstLogin,newPassword'] ===
                      valueById['firstLogin,confirmPassword'] ? (
                      <Icon
                        id="check-circle-fill"
                        className="text-success"
                        presentation
                      />
                    ) : (
                      ''
                    )
                  }
                />
                {error && <div className="invalid-feedback">{error}</div>}

                <Button
                  className="mt-4"
                  color="primary"
                  type="submit"
                  disabled={!!formInvalid}
                >
                  Save new password
                </Button>
              </>
            )}
          </FormProvider>
        </div>
      </div>
    )
  }
}

export default withRouter(FirstTimeLoginScreen)
