import React, { Component } from 'react'
import { withAuth } from '../../state/auth'
import {
  validateResponse,
  gateway,
  gatewayMapping,
  gatewayHost,
} from '../../service/util'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import './SwaggerDisplay.css'

class SwaggerDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = { spec: null }
  }

  /* Until swagger-ui-react passes through and swagger-ui respects an
   * option to include credentials, we have to fetch it ourselves.
   * https://github.com/swagger-api/swagger-ui/blob/master/flavors/swagger-ui-react/index.js#L13
   * https://github.com/swagger-api/swagger-ui/blob/master/docs/usage/configuration.md
   */
  componentDidMount() {
    if (this.props.location.pathname) {
      const url = gateway(
        this.props.match.params.hostedAt,
        // Result of wildcard match using *
        `/${this.props.match.params[0]}`,
      )
      fetch(url, { credentials: 'include' })
        .then(validateResponse)
        .then(res => res.json())
        .then(spec => {
          // if the version is swagger then use these values
          if (spec.swagger)
            return {
              ...spec,
              host: gatewayHost(this.props.match.params.id),
              schemes: [gatewayMapping().protocol],
              basePath: `/${this.props.match.params.id}`,
            }
          // if there version is openapi use these values
          if (spec.openapi)
            return {
              ...spec,
              host: undefined,
              schemes: undefined,
              basePath: undefined,
              servers: [
                {
                  url: `${gatewayMapping().protocol}://${gatewayHost(
                    this.props.match.params.id,
                  )}/${this.props.match.params.id}`,
                },
              ],
            }
          // defaulting to this
          return {
            ...spec,
            host: gatewayHost(this.props.match.params.id),
            schemes: [gatewayMapping().protocol],
            basePath: `/${this.props.match.params.id}`,
          }
        })
        .then(spec => this.setState({ spec }))
    }
    this.forceUpdate()
  }

  render = () => {
    const spec = this.state.spec
    if (spec) {
      return (
        <SwaggerUI
          supportedSubmitMethods={spec.openapi ? [] : undefined}
          spec={spec}
        />
      )
    } else {
      return <div />
    }
  }
}

export default withAuth(SwaggerDisplay)
