import React from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

const Footer = () => {
  const location = useLocation()
  const managerPage =
    location.pathname === '/token-manager' || location.pathname === '/user-manager'

  const d = new Date()
  const year = d.getFullYear()

  return (
    <footer
      className={classNames('bg-primary900 mt-2 footer-position', {
        'footer-width': managerPage,
        'sidebar-margin': managerPage,
      })}
    >
      <div className="container-fluid">
        <p className="m-0 mt-2 text-white">&copy; {year} HealthSparq</p>
      </div>
    </footer>
  )
}

export default Footer
