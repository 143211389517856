export class NotAuthenticatedError extends Error {}
export class ForbiddenError extends Error {}

const SERVICE_MAPPINGS = intoMap([
  {
    // Running just the UI locally is the same as running from dev2.
    hosts: [
      'd3lku2alvj9b34.cloudfront.net',
      'developers.dev2.healthsparq.com',
      'localhost',
    ],
    protocol: 'https',
    services: {
      appGateway: 'healthsparq-app-gateway.dev2.healthsparq.com',
      apiGateway: 'healthsparq-api-gateway.dev2.healthsparq.com',
    },
  },
  {
    // Development of UI and backend together in docker uses a mapped port.
    hosts: ['localhost'],
    port: '8080',
    protocol: 'http',
    services: {
      appGateway: 'localhost:8080',
      apiGateway: 'localhost:8080',
    },
  },
  {
    hosts: ['d2085d7n4vadqe.cloudfront.net', 'developers.uat2.healthsparq.com'],
    protocol: 'https',
    services: {
      appGateway: 'app-gateway.uat2.healthsparq.com',
      apiGateway: 'api-gateway.uat2.healthsparq.com',
    },
  },
  {
    hosts: ['developers.healthsparq.com'],
    protocol: 'https',
    services: {
      appGateway: 'app-gateway.healthsparq.com',
      apiGateway: 'api-gateway.healthsparq.com',
    },
  },
])

export function validateResponse(res) {
  if (res.status === 401) {
    throw new NotAuthenticatedError()
  } else if (res.status !== 200) {
    throw new Error()
  }
  return res
}

export function gateway(service, path, currentHostname, currentPort) {
  const mapping = gatewayMapping(currentHostname, currentPort)
  const host = window.location.hostname
  const port = window.location.port ? `:${window.location.port}` : ''
  const gateway = mapping.gateways[gatewayType(service)]

  if (path.includes('/swagger-ui')) {
    if (port.startsWith(':30')) {
      // If you're manually running the UI without the backend (port 3000/3001), point swagger docs to dev2
      return `${
        mapping.protocol
      }://developers.dev2.healthsparq.com/swagger/${service}/${service}/v2/api-docs`
    }
    // Otherwise, point swagger docs to the host (i.e. developers.dev2.healthsparq.com), with or without port
    return `${
      mapping.protocol
    }://${host}${port}/swagger/${service}/${service}/v2/api-docs`
  } else {
    // If path does not point to swagger docs, route to appropriate gateway
    return `${mapping.protocol}://${gateway}/${service}${path}`
  }
}

export function gatewayType(service) {
  const isApp = service.endsWith('-app-service')
  return isApp ? 'appGateway' : 'apiGateway'
}

export function gatewayHost(service, currentHostname, currentPort) {
  const mapping = gatewayMapping(currentHostname, currentPort)
  const gateway = mapping.gateways[gatewayType(service)]
  return gateway
}

export function gatewayMapping(currentHostname, currentPort) {
  currentHostname = currentHostname || window.location.hostname
  currentPort = currentPort || window.location.port

  return (
    SERVICE_MAPPINGS[hostPort(currentHostname, currentPort)] ||
    SERVICE_MAPPINGS[currentHostname] ||
    {}
  )
}

function hostPort(host, port) {
  return host + ':' + port
}

function intoMap(mappings) {
  const result = {}
  mappings.forEach(mapping => {
    mapping.hosts.forEach(host => {
      const value = { protocol: mapping.protocol, gateways: mapping.services }
      if (mapping.port) {
        result[hostPort(host, mapping.port)] = value
      } else {
        result[host] = value
      }
    })
  })
  return result
}
