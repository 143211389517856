import api from './api'
import { gateway } from 'service/util'

const service = 'healthsparq-api-token-service'

const cookieLogin = (user, pwd) => {
  return api.fetch(gateway(service, '/v2/token/cookie?domain=janus'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body:
      'username=' +
      encodeURIComponent(user) +
      '&password=' +
      encodeURIComponent(pwd),
  })
}

const firstTimeLogin = (user, tempPass, newPass) => {
  return api.fetch(gateway(service, '/v2/firstlogin'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: user,
      temporaryPassword: tempPass,
      newPassword: newPass,
    }),
  })
}

const getLoginParameters = () => {
  return api.fetch(gateway(service, '/v2/passwordpolicy'))
}

const logout = () => {
  return api.fetch(gateway(service, '/v2/logout'), {
    method: 'POST',
  })
}

const confirmPassword = (user, tempPass, newPass) => {
  return api.fetch(
    gateway('healthsparq-api-token-service', '/v2/confirmpassword'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: user,
        newPassword: newPass,
        verificationCode: tempPass,
      }),
    },
  )
}

const forgotPassword = username => {
  return api.fetch(gateway(service, `/v2/forgotpassword/${username}`), {
    method: 'POST',
  })
}

const resetDevPassword = user => {
  return api.fetch(gateway(service, `/v2/adminresetpassword/${user}`), {
    method: 'POST',
    data: user,
  })
}

export default {
  getLoginParameters,
  logout,
  confirmPassword,
  cookieLogin,
  firstTimeLogin,
  resetDevPassword,
  forgotPassword,
}
