import { connect } from 'react-redux'
import { actionCreators } from 'dux/display'

import UserManagerScreen from './UserManagerScreen.jsx'

const mapDispatchToProps = {
  addToastNotification: actionCreators.addToastNotification,
}

export default connect(
  null,
  mapDispatchToProps,
)(UserManagerScreen)
