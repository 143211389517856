// import { validateResponse, gateway } from "../service/util"
import api from './api'
import { gateway } from 'service/util'

// These endpoints are accessible by client developer accounts with a token-admin role.

const service = 'healthsparq-api-token-service'
const prefDir = '/v2/apikey'

// COPYING FROM HUB STYLE OF MAKING API CALLS
const fetchAllKeys = () => {
  return api.fetch(gateway(service, `${prefDir}/list`))
}

const createNewKey = () => {
  return api.fetch(gateway(service, prefDir), {
    method: 'POST',
  })
}

const rotateSecret = id => {
  return api.fetch(gateway(service, `${prefDir}/${id}/rotate`), {
    method: 'POST',
  })
}

const deleteKey = id => {
  return api.fetch(gateway(service, `${prefDir}/${id}`), {
    method: 'DELETE',
  })
}

const updateNotes = (id, note) => {
  return api.fetch(gateway(service, `${prefDir}/${id}`), {
    method: 'PATCH',
    data: note,
  })
}

const assignRole = (id, role) => {
  return api.fetch(gateway(service, `${prefDir}/${id}/roles`), {
    method: 'PUT',
    data: role,
  })
}

const createImpersonatedKey = user => {
  return api.fetch(gateway(service, `${prefDir}/impersonation`), {
    method: 'POST',
    data: {},
  })
}

export default {
  assignRole,
  createImpersonatedKey,
  createNewKey,
  deleteKey,
  fetchAllKeys,
  rotateSecret,
  updateNotes,
}
