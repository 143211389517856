import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'
import { useHistory, useLocation, withRouter } from 'react-router-dom'

import { getUserRoles } from 'utils/cookie-monster.js'

import { Button, FilterBar, LoadingIndicator } from '@hsq/razzle-dazzle'
import apiKey from 'api/apikeys'
import RotateSecretModal from './RotateSecretModal/RotateSecretModal.jsx'
import SideNav from 'component/App/SideNav.jsx'
import EditSidebar from 'component/universal/EditSidebar/EditSidebar.jsx'
import Breadcrumbs from 'component/App/Breadcrumbs.jsx'
import TableRow from 'component/universal/TableRow/TableRow.jsx'

import { component } from './KeyManagementScreen.module.scss'

const propTypes = {
  addToastNotification: func.isRequired,
}

const KeyManagementScreen = ({ addToastNotification }) => {
  const [apiKeys, setApiKeys] = useState(null)
  const [currentKey, setCurrentKey] = useState({})
  const [alertError, setAlertError] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [renderRotateModal, setRenderRotateModal] = useState(false)
  const [rotatedSecret, setRotatedSecret] = useState('')
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const history = useHistory()
  const location = useLocation()

  let roles = []
  roles = getUserRoles()

  useEffect(() => {
    if (roles && (!roles.includes('token-admin') && !roles.includes('hub-admin')))
      history.push('/')
    loadKeys()
  }, [])

  // API CALLS
  const loadKeys = () => {
    apiKey.fetchAllKeys().then(({ data, error }) => {
      if (data && data.users) setApiKeys(data.users)
      else errorHandler(error)
    })
  }

  const createKey = () => {
    apiKey.createNewKey().then(({ data, error }) => {
      if (data && data.key) {
        setCurrentKey(data.key)
        setRenderRotateModal(true)
        loadKeys()
      } else errorHandler(error)
    })
  }

  const createImpersonatedKey = () => {
    apiKey.createImpersonatedKey().then(({ data, error }) => {
      if (data && data.key) {
        setCurrentKey(data.key)
        setRenderRotateModal(true)
        loadKeys()
      } else errorHandler(error)
    })
  }

  const rotateSecret = (id, showModal) => {
    apiKey.rotateSecret(id).then(({ data, error }) => {
      if (data && data.key) {
        setRotatedSecret(data.key.secret)
        if (showModal) {
          setCurrentKey(data.key)
          setRenderRotateModal(true)
        }
      } else errorHandler(error)
    })
  }

  const saveNote = (e, key, notes) => {
    e.preventDefault()
    apiKey.updateNotes(key, { notes }).then(({ response, error }) => {
      if (response && response.ok) {
        loadKeys()
        addToastNotification({
          id: `update-${key}-note-success`,
          body: 'Changes saved',
          status: 'success',
        })
      } else errorHandler(error)
    })
  }

  const deleteKey = id => {
    apiKey.deleteKey(id).then(({ response, error }) => {
      if (response && response.ok) {
        loadKeys()
        addToastNotification({
          id: `delete-key-${id}-success`,
          body: 'Key deleted',
          status: 'success',
        })
      } else errorHandler(error)
    })
  }

  const toggleSidebar = () => {
    if (sidebarOpen) {
      setCurrentKey({})
      setRotatedSecret('')
      setAlertError('')
    }
    setSidebarOpen(!sidebarOpen)
  }

  const editKey = item => {
    setCurrentKey(item)
    toggleSidebar()
  }

  const handleInput = value => {
    setFilterValue(value)
  }

  const filterKeys = key => {
    const value = filterValue
    if (value) {
      return (
        key.id.toLowerCase().includes(value.toLowerCase()) ||
        (key.notes && key.notes.toLowerCase().includes(value.toLowerCase()))
      )
    }
    return true
  }

  const errorHandler = error => {
    if (error === 'Unauthorized') {
      history.push('/login?redirect=' + location.pathname)
    } else if (sidebarOpen) setAlertError(error)
    else
      addToastNotification({
        id: `toast-${error}-error`,
        body: error,
        status: 'warning',
      })
  }

  const renderRows = () => {
    if (apiKeys) {
      return apiKeys.filter(filterKeys).map(key => {
        return (
          <TableRow
            rowItem={key}
            admin={roles ? roles.includes('hub-admin') : false}
            key={key.id}
            deleteItem={deleteKey}
            editItem={editKey}
            rotateSecret={rotateSecret}
            type="key"
          />
        )
      })
    }
  }

  return (
    <>
      <SideNav />
      <Breadcrumbs />
      <main className={`${component} pt-3 px-3`} role="main">
        <EditSidebar
          error={alertError}
          editItem={currentKey}
          rotateSecret={rotateSecret}
          newSecret={rotatedSecret}
          save={saveNote}
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
          type="key"
        />
        <RotateSecretModal
          closeModal={() => {
            setRenderRotateModal(false)
            setRotatedSecret('')
          }}
          showModal={renderRotateModal}
          currentKey={currentKey}
          newSecret={rotatedSecret}
        />

        <div className="d-flex flex-column justify-content-between mt-2 pt-2">
          <div className="d-flex justify-content-between">
            <div>
              <h2>Key Management</h2>
              <span>Create and manage API keys for healthsparq.com </span>
            </div>
            <div>
              <Button
                className="bg-primary300 text-white"
                onClick={
                  roles && roles.includes('hub-admin')
                    ? createImpersonatedKey
                    : createKey
                }
              >
                Create Key
              </Button>
            </div>
          </div>
          <div className="w-50">
            <FilterBar
              className="w-75 mb-4"
              placeholder="Filter"
              onChange={handleInput}
              value={filterValue}
              debounced
            />
          </div>
          {!apiKeys ? (
            <LoadingIndicator />
          ) : (
            <table className="table border">
              <thead className="border-bottom">
                <tr className="bg-gray14">
                  <th className="border-right key-column">Key</th>
                  <th className="border-right">Notes</th>
                  <th className="border-right key-actions" />
                </tr>
              </thead>

              <tbody>
                {apiKeys && !apiKeys.length ? (
                  <tr>
                    <td colSpan="3">No API keys</td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </tbody>
            </table>
          )}
        </div>
      </main>
    </>
  )
}

KeyManagementScreen.propTypes = propTypes
export default withRouter(KeyManagementScreen)
