import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Card } from '@hsq/razzle-dazzle'

import { component } from './LoginScreen.module.scss'
import LoginScreen from './LoginScreen.jsx'
import logo from 'media/HSQ-logo.svg'

const LoginScreenContainer = () => {
  const history = useHistory() || {}
  const location = useLocation() || {}

  return (
    <div
      className={`${component} d-flex mh-v100 justify-content-center align-items-center mb-5`}
    >
      <Card className="login-card card-inverse">
        <Card.Header className="px-5 py-4 d-flex justify-content-between align-content-center">
          <img src={logo} alt="HealthSparq" className="mr-1 logo" />
          <span className="badge badge-dark align-content-center title p-2 font-weight-bold">
            DevHub
          </span>
        </Card.Header>
        <Card.Body className="px-5">
          <LoginScreen history={history} location={location} />
        </Card.Body>
      </Card>
    </div>
  )
}

export default LoginScreenContainer
