import React, { Component } from 'react'
import { bool, func, shape, string } from 'prop-types'
import { Button, Input, Modal } from '@hsq/razzle-dazzle'

import copyText from 'utils/copy-text.js'

class RotateSecretModal extends Component {
  static propTypes = {
    closeModal: func.isRequired,
    currentKey: shape().isRequired,
    newSecret: string.isRequired,
    showModal: bool.isRequired,
  }

  state = {
    newSecret: '',
  }

  /**
   * Dispatch close action just in case
   */
  componentWillUnmount() {
    const { closeModal } = this.props
    closeModal()
  }

  render() {
    const { closeModal, currentKey, newSecret, showModal } = this.props
    return (
      <span data-test="rotate-secret-modal">
        <Modal active={showModal} deactivate={closeModal}>
          <Modal.Header className="align-items-center bg-primary900">
            <Modal.Title>{newSecret ? 'Rotate secret' : 'Create key'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className="mt-2">
                <span className="font-weight-bold small-text">
                  {newSecret ? 'Key:' : 'Key created:'}
                </span>
                <br />
                {currentKey.id}
              </p>
              <div className="secret-input">
                <Input
                  id="secret"
                  disabled={!newSecret && !currentKey.secret}
                  label={newSecret ? 'New secret:' : 'Key secret:'}
                  onChange={() => {}}
                  value={newSecret || currentKey.secret}
                  placeholder="******************************"
                  decoration={
                    <Button
                      className="m-2"
                      size="small"
                      color="primary"
                      onClick={() => copyText('secret')}
                      outline
                    >
                      Copy
                    </Button>
                  }
                />
                <p className="text-danger small">
                  Please copy this secret to a safe place for future security needs.
                  It will be unavailable after closing this window.
                </p>
              </div>
            </div>
            <Button
              className="text-primary mt-4 border border-primary"
              onClick={closeModal}
            >
              I understand
            </Button>
          </Modal.Body>
        </Modal>
      </span>
    )
  }
}

export default RotateSecretModal
