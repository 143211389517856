// import { validateResponse, gateway } from "../service/util"
import api from './api'
import { gateway } from 'service/util'

// These endpoints are accessible by client admins for the management of client developer accounts

const service = 'healthsparq-api-token-service'
const prefDir = '/v2/client/developer'

const fetchDevelopers = () => {
  return api.fetch(gateway(service, `${prefDir}/list`))
}

const createDeveloper = (user, notes) => {
  return api.fetch(gateway(service, prefDir), {
    method: 'POST',
    data: {
      email: user,
      notes,
    },
  })
}

const assignRoles = (user, roles) => {
  return api.fetch(gateway(service, `${prefDir}/${user}/roles`), {
    method: 'PUT',
    data: roles,
  })
}

const updateNotes = (user, notes) => {
  return api.fetch(gateway(service, `${prefDir}/${user}`), {
    method: 'PATCH',
    data: notes,
  })
}

const deleteDeveloper = user => {
  return api.fetch(gateway(service, `${prefDir}/${user}`), {
    method: 'DELETE',
  })
}

export default {
  assignRoles,
  createDeveloper,
  deleteDeveloper,
  fetchDevelopers,
  updateNotes,
}
