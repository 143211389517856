import React, { Component } from 'react'
import { Subscribe, Container } from 'unstated'
import ls from 'local-storage'

export default class AuthContainer extends Container {
  state = {
    isAuthenticated: false,
  }

  setAuthenticated(val) {
    this.setState({ isAuthenticated: val })
    ls.set('userAuthenticated', val)
  }

  isAuthenticated() {
    if (typeof ls.get('userAuthenticated') == 'undefined') {
      return false
    } else {
      return ls.get('userAuthenticated')
    }
  }
}

export function withAuth(Clazz) {
  class Wrapped extends Component {
    render() {
      return (
        <Subscribe to={[AuthContainer]}>
          {auth => <Clazz {...this.props} auth={auth} />}
        </Subscribe>
      )
    }
  }
  return Wrapped
}
