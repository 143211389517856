// Actions
// ---------------------------------------------------------------------------

export const ADD_TOAST_NOTIFICATION = `ADD_TOAST_NOTIFICATION`
export const REMOVE_TOAST_NOTIFICATION = `REMOVE_TOAST_NOTIFICATION`

// Reducers
// ---------------------------------------------------------------------------
export default function display(
  state = {
    toastNotifications: [],
  },
  { type, id, data, payload = {} },
) {
  switch (type) {
    case ADD_TOAST_NOTIFICATION:
      return {
        ...state,
        toastNotifications: [...state.toastNotifications, data],
      }
    case REMOVE_TOAST_NOTIFICATION:
      return {
        ...state,
        toastNotifications: [
          ...state.toastNotifications.filter(el => el.id !== id),
        ],
      }
    default:
      return state
  }
}

// Action Creators
// ---------------------------------------------------------------------------
export const addToastNotification = data => ({
  type: ADD_TOAST_NOTIFICATION,
  data,
})

export const removeToastNotification = id => ({
  type: REMOVE_TOAST_NOTIFICATION,
  id,
})

export const actionCreators = {
  addToastNotification,
  removeToastNotification,
}

// Selectors
// ---------------------------------------------------------------------------
const getToastNotifications = store => store.display.toastNotifications

export const selectors = {
  getToastNotifications,
}
