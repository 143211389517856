import React from 'react'
import { array } from 'prop-types'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import logo from 'media/HSQ-logo.svg'
import { withAuth } from 'state/auth.js'
import { getUserRoles, getCookie } from 'utils/cookie-monster.js'

import { component } from './Header.module.scss'

const propTypes = {
  roles: array,
}

const Header = () => {
  const location = useLocation()
  const route = location.pathname

  const noNavRoutes = ['/activate', '/forgot-password', '/reset-password', '/']
  const showNav = !noNavRoutes.includes(route)

  let roles = []
  roles = getUserRoles()

  let cookie
  cookie = getCookie('Token')

  return route !== '/login' ? (
    <div className={component}>
      <div className="header-padding-handler" />
      <nav className="navbar navbar-expand-md fixed-top bg-primary900">
        <img src={logo} alt="HealthSparq" className="mr-1 logo navbar-brand" />
        <div className="text-white title p-2 font-weight-bold">DevHub</div>

        {route === '/' && (
          <div className="collapse navbar-collapse justify-content-end">
            <Link
              className={classNames('mr-3 font-weight-bold', {
                'border-bottom': route === '/docs',
              })}
              to="/docs"
            >
              Documentation
            </Link>
            <Link className="mr-3 font-weight-bold" to="/login">
              Login
            </Link>
          </div>
        )}

        {showNav && (
          <div className="collapse navbar-collapse justify-content-end">
            <Link
              className={classNames('mr-3 font-weight-bold', {
                'border-bottom': route === '/docs',
              })}
              to="/docs"
            >
              Documentation
            </Link>
            {roles && roles.includes('hub-admin') && (
              <Link
                className={classNames('mr-3 font-weight-bold', {
                  'border-bottom': route === '/user-manager',
                })}
                to="user-manager"
              >
                User Management
              </Link>
            )}
            {roles &&
              (roles.includes('token-admin') || roles.includes('hub-admin')) && (
                <Link
                  className={classNames('mr-3 font-weight-bold', {
                    'border-bottom': route === '/token-manager',
                  })}
                  to="/token-manager"
                >
                  Key Management
                </Link>
              )}
            <Link
              className={classNames('mr-3 font-weight-bold', {
                'border-bottom': route === Link.to,
              })}
              to={cookie ? '/logout' : '/login'}
            >
              {cookie ? 'Logout' : 'Login'}
            </Link>
          </div>
        )}
      </nav>
    </div>
  ) : (
    ''
  )
}

export default withAuth(Header)
Header.propTypes = propTypes
