/* eslint-env node */
import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
// import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers'
// import rootSaga from './sagas'
// import propTypesMiddleware from './utils/propTypesMiddleware'

// const sagaMiddleware = createSagaMiddleware()
/* eslint-disable no-underscore-dangle */
const composeWithReduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: 'HealthSparq Dev Hub',
    })
  : compose

// Use correct enhancers fn, checking for redux devtools extension in non prod envs
const storeEnhancers =
  process.env.NODE_ENV === 'development' ||
  window.location.href.includes('debug=true')
    ? composeWithReduxDevTools
    : compose

// Create store, overriding preloaded state if needed
const store = createStore(
  rootReducer,
  /* preloadedState, */
  storeEnhancers(
    applyMiddleware(thunk), // Thunks for simple async
    // applyMiddleware(sagaMiddleware), // Sagas for those sticky situations
    // TODO: Figure out how to avoid recalling the root reducer to use this
    // applyMiddleware(propTypesMiddleware), // Check redux store propTypes in dev (NOTE: put this last in storeEnhancers)
  ),
)

// Fire up those generators
// (Passing dispatch in to allow thunks to be called directly from inside sagas)
// sagaMiddleware.run(rootSaga, store.dispatch)

// Accept hot reload for reducers in dev envs
if (module.hot) {
  module.hot.accept('./reducers', () => {
    store.replaceReducer(rootReducer)
  })
}

export default store
