import React, { useState } from 'react'
import { shape } from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from '@hsq/razzle-dazzle'
import { FormProvider, SchemaNode } from '@hsq/formogorgon'

import api from 'api/login'

import { deleteCookie, getCookie, setCookie } from 'utils/cookie-monster'

const propTypes = {
  history: shape().isRequired,
  location: shape().isRequired,
}

const LoginScreen = ({ history, location }) => {
  const usernameCookie = getCookie('username') || ''
  const [error, setError] = useState('')
  const redirect = location.search ? `/${location.search.split('/')[1]}` : ''

  const updateUsernameCookie = ({ username, remember }) => {
    if (!remember) {
      deleteCookie('username')
    } else if (remember && username) {
      setCookie('username', username)
    }
  }

  const handleUserLogin = ({ password, username, remember }) => {
    updateUsernameCookie({ username, remember })
    api.cookieLogin(username, password).then(({ status, error }) => {
      if (error) setError(`Problem with authentication: ${error}`)
      else
        history.push({
          pathname: redirect || '/docs',
        })
    })
  }

  const formSchema = {
    type: 'object',
    properties: {
      username: {
        type: 'string',
        title: 'User email',
      },
      password: {
        typeOverride: 'password',
        title: 'Password',
        type: 'string',
      },
      remember: {
        title: 'Remember me',
        type: 'boolean',
        default: !!usernameCookie,
        onChange: ({ value }) => updateUsernameCookie({ remember: value }),
      },
    },
    required: ['username', 'password'],
  }

  return (
    <>
      <FormProvider
        formId="loginForm"
        defaultSchema={formSchema}
        onSubmit={handleUserLogin}
      >
        {({ formInvalid }) => (
          <>
            <SchemaNode materializedPath="loginForm,username" />
            <SchemaNode materializedPath="loginForm,password" />
            <SchemaNode materializedPath="loginForm,remember" />
            {error && <div className="invalid-feedback">{error}</div>}
            <div className="form-group align-items-center d-flex flex-column">
              <Button
                className="mb-3 w-50"
                color="light"
                outline
                type="submit"
                disabled={!!formInvalid}
              >
                Log In
              </Button>
              <Link to="/forgot-password">Forgot password?</Link>
            </div>
          </>
        )}
      </FormProvider>
    </>
  )
}

LoginScreen.propTypes = propTypes
export default LoginScreen
