import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'
import { useHistory, useLocation, withRouter } from 'react-router-dom'

import { Button, FilterBar, LoadingIndicator } from '@hsq/razzle-dazzle'

import { getUserRoles } from 'utils/cookie-monster.js'

import usersApi from 'api/usersApi'
import loginApi from 'api/login'
import SideNav from 'component/App/SideNav.jsx'
import EditSidebar from 'component/universal/EditSidebar/EditSidebar.jsx'
import Breadcrumbs from 'component/App/Breadcrumbs.jsx'
import TableRow from 'component/universal/TableRow/TableRow.jsx'

import { component } from './UserManagementScreen.module.scss'

const propTypes = {
  addToastNotification: func.isRequired,
}

const UserManagerScreen = ({ addToastNotification }) => {
  const [users, setUsers] = useState([])
  const [currentUser, setCurrentUser] = useState({})
  const [alertError, setAlertError] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isLoadingUsers, setLoadingUsers] = useState(false)

  const history = useHistory()
  const location = useLocation()

  let roles = []
  roles = getUserRoles()

  useEffect(() => {
    if (roles && !roles.includes('hub-admin')) history.push('/')
    loadUsers()
  }, [])

  // API CALLS
  const loadUsers = () => {
    setLoadingUsers(true)
    usersApi.fetchDevelopers().then(({ data, error }) => {
      if (data && data.users) setUsers(data.users)
      else errorHandler(error)
      setLoadingUsers(false)
    })
  }

  const createUser = (e, user, notes) => {
    e.preventDefault()
    usersApi.createDeveloper(user, notes).then(({ response, error }) => {
      if (response && response.ok) {
        loadUsers()
        addToastNotification({
          id: `create-user-${user}-success`,
          body: 'User created',
          status: 'success',
        })
      } else errorHandler(error)
    })
  }

  const saveNote = (e, user, notes) => {
    e.preventDefault()
    usersApi.updateNotes(user, { notes }).then(({ response, error }) => {
      if (response && response.ok) {
        loadUsers()
        addToastNotification({
          id: `update-${user}-notes-success`,
          body: 'Changes saved',
          status: 'success',
        })
      } else errorHandler(error)
    })
  }

  const deleteUser = id => {
    const email = encodeURIComponent(id)
    usersApi.deleteDeveloper(email).then(({ response, error }) => {
      if (response && response.ok) {
        loadUsers()
        addToastNotification({
          id: `delete-user-${id}-success`,
          body: 'User deleted',
          status: 'success',
        })
      } else errorHandler(error)
    })
  }

  const resetPassword = id => {
    loginApi.resetDevPassword(id).then(({ response, error }) => {
      if (response.ok) {
        addToastNotification({
          id: `reset-user-${id}-success`,
          body: 'Password reset email sent',
          status: 'success',
        })
      } else errorHandler(error)
    })
  }

  const toggleSidebar = () => {
    if (sidebarOpen) {
      setCurrentUser({})
      setAlertError('')
    }
    setSidebarOpen(!sidebarOpen)
  }

  const editUser = user => {
    setCurrentUser(user)
    toggleSidebar()
  }

  const filterUsers = user => {
    if (filterValue) {
      return (
        user.id.toLowerCase().includes(filterValue.toLowerCase()) ||
        (user.notes && user.notes.toLowerCase().includes(filterValue.toLowerCase()))
      )
    }
    return true
  }

  const errorHandler = error => {
    if (error === 'Unauthorized') {
      history.push('/login?redirect=' + location.pathname)
    } else if (sidebarOpen) setAlertError(error)
    else
      addToastNotification({
        id: `toast-${error}-error`,
        body: error,
        status: 'warning',
      })
  }

  const renderRows = () => {
    if (users) {
      return users.filter(filterUsers).map(user => {
        return (
          <TableRow
            rowItem={user}
            key={user.id}
            deleteItem={deleteUser}
            editItem={editUser}
            resetPassword={resetPassword}
            type="user"
          />
        )
      })
    }
  }

  return (
    <>
      <SideNav />
      <Breadcrumbs />
      <main className={`${component} pt-3 px-3`} role="main">
        <EditSidebar
          editItem={currentUser}
          error={alertError}
          save={currentUser.id ? saveNote : createUser}
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
          type="user"
        />

        <div className="d-flex flex-column justify-content-between mt-2 pt-2">
          <div className="d-flex justify-content-between">
            <div>
              <h2>User Management</h2>
              <span>Create and manage user accounts </span>
            </div>
            <div>
              <Button
                className="bg-primary300 text-white"
                onClick={() => toggleSidebar()}
              >
                Create User
              </Button>
            </div>
          </div>
          <div className="w-50">
            <FilterBar
              className="w-75 mb-4"
              placeholder="Filter"
              onChange={setFilterValue}
              value={filterValue}
              debounced
            />
          </div>
          {isLoadingUsers ? (
            <LoadingIndicator />
          ) : (
            <table className="table border-bottom border-left">
              <thead>
                <tr className="bg-gray14">
                  <th className="border-right key-column">User Email</th>
                  <th className="border-right">Notes</th>
                  <th className="border-right key-actions" />
                </tr>
              </thead>

              <tbody>
                {!users.length ? (
                  <td className="border-right" colSpan={3}>
                    No saved users
                  </td>
                ) : (
                  renderRows()
                )}
              </tbody>
            </table>
          )}
        </div>
      </main>
    </>
  )
}

UserManagerScreen.propTypes = propTypes
export default withRouter(UserManagerScreen)
