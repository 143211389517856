import { connect } from 'react-redux'
import { actionCreators } from 'dux/display'

import KeyManagementScreen from './KeyManagementScreen.jsx'

const mapDispatchToProps = {
  addToastNotification: actionCreators.addToastNotification,
}

export default connect(
  null,
  mapDispatchToProps,
)(KeyManagementScreen)
