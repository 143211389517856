import React, { useState } from 'react'
import { bool, func, shape, string } from 'prop-types'
import { Dropdown } from '@hsq/razzle-dazzle'

const propTypes = {
  admin: bool,
  rowItem: shape().isRequired,
  deleteItem: func.isRequired,
  editItem: func.isRequired,
  resetPassword: func,
  rotateSecret: func,
  type: string.isRequired,
}

const defaultProps = {
  admin: false,
  resetPassword: () => {},
  rotateSecret: () => {},
}

const TableRow = ({
  admin,
  deleteItem,
  editItem,
  resetPassword,
  rotateSecret,
  rowItem,
  type,
}) => {
  const [deleteStatus, setDeleteStatus] = useState(false)
  const [utilityStatus, setUtilityStatus] = useState(false)

  const handleClicks = e => {
    const { action } = e.target.dataset
    setDeleteStatus(false)
    setUtilityStatus(false)
    if (action === 'edit') editItem(rowItem)
    else if (action === 'delete') deleteItem(rowItem.id)
    else if (action === 'rotate') rotateSecret(rowItem.id, true)
    else if (action === 'reset') resetPassword(rowItem.id)
  }
  return (
    <tr className="bg-white" key={rowItem.id}>
      <td className="border-right table-column-padding align-middle">
        {rowItem.id}
      </td>
      <td className="border-right table-column-padding align-middle">
        {rowItem.notes}
      </td>
      <td className="border-right key-actions p-1 align-middle">
        <Dropdown onDeactivated={handleClicks}>
          <div className="dropdown bottom">
            <Dropdown.Trigger color="secondary">Actions</Dropdown.Trigger>
            <Dropdown.Content className="dropdown-spacing">
              <div className="mb-2">
                <b className="dropdown-header text-dark pb-1 text-uppercase">
                  MANAGE {type}
                </b>
                {!admin && (
                  <Dropdown.Item className="py-0" data-action="edit">
                    Edit {type}
                  </Dropdown.Item>
                )}
                {deleteStatus && (
                  <Dropdown.Item className="py-0" data-action="delete">
                    <>
                      Confirm deletion?
                      <span
                        className="text-danger ml-2 text-decoration-none"
                        data-action="delete"
                      >
                        Yes
                      </span>
                    </>
                  </Dropdown.Item>
                )}
                {!deleteStatus && (
                  <Dropdown.Item
                    className="py-0"
                    onClick={() => setDeleteStatus(true)}
                  >
                    Delete {type}
                  </Dropdown.Item>
                )}
              </div>
              <div className="mb-1">
                <b className="dropdown-header text-dark pb-1">UTILITY</b>
                {utilityStatus && (
                  <Dropdown.Item
                    className="py-0"
                    data-action={type === 'key' ? 'rotate' : 'reset'}
                  >
                    <>
                      Confirm {type === 'key' ? 'rotate' : 'reset'}?
                      <span
                        className="text-danger ml-2 text-decoration-none"
                        data-action={type === 'key' ? 'rotate' : 'reset'}
                      >
                        Yes
                      </span>
                    </>
                  </Dropdown.Item>
                )}
                {!utilityStatus && (
                  <Dropdown.Item
                    className="py-0"
                    onClick={() => setUtilityStatus(true)}
                  >
                    {type === 'key' ? 'Rotate secret' : 'Reset password'}
                  </Dropdown.Item>
                )}
              </div>
            </Dropdown.Content>
          </div>
        </Dropdown>
      </td>
    </tr>
  )
}

TableRow.propTypes = propTypes
TableRow.defaultProps = defaultProps

export default TableRow
