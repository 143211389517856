import React, { useState, useEffect } from 'react'
import { bool, func, shape, string } from 'prop-types'
import { Alert, Button, Input, Sidebar } from '@hsq/razzle-dazzle'

import copyText from 'utils/copy-text.js'

const propTypes = {
  editItem: shape({}),
  error: string,
  rotateSecret: func,
  save: func.isRequired,
  sidebarOpen: bool.isRequired,
  toggleSidebar: func.isRequired,
  type: string.isRequired,
}

const defaultProps = {
  editItem: {},
  error: '',
  rotateSecret: () => {},
}

const EditSidebar = ({
  editItem,
  error,
  newSecret,
  rotateSecret,
  save,
  sidebarOpen,
  toggleSidebar,
  type,
}) => {
  const [noteEdit, setNoteEdit] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [rotateStatus, setRotateStatus] = useState(false)

  useEffect(() => {
    setNoteEdit(editItem.notes)
  }, [editItem])

  const closeSidebar = () => {
    toggleSidebar()
    setNoteEdit('')
    setUserEmail('')
    setRotateStatus(false)
  }

  const editUserUsernameSection = () => (
    <Input disabled label="User email" value={editItem.id} onChange={() => {}} />
  )

  const newUserUsernameSection = () => (
    <Input
      label="User email"
      onChange={e => setUserEmail(e.target.value)}
      value={userEmail}
      invalid={
        !userEmail || userEmail.match(/\S+@\S+\.\S+/)
          ? ''
          : 'Please enter a valid email'
      }
    />
  )

  const rotateSecretSection = () => {
    return (
      <>
        <h4>Key: {editItem.id}</h4>
        <div className="secret-input">
          <Input
            disabled={!editItem.secret}
            label="Secret"
            id="secret"
            onChange={() => {}}
            decoration={
              <Button
                className="m-2"
                size="small"
                color="primary"
                onClick={
                  rotateStatus
                    ? () => copyText('secret')
                    : () => {
                        rotateSecret(editItem.id, false)
                        setRotateStatus(true)
                      }
                }
                outline
              >
                {rotateStatus ? 'Copy' : 'Rotate'}
              </Button>
            }
            value={newSecret}
            placeholder="******************************"
          />
        </div>
        {newSecret && (
          <p className="text-danger">
            Please copy this secret to a safe place for future security needs. It
            will be unavailable after closing this window.
          </p>
        )}
      </>
    )
  }

  return (
    <Sidebar
      open={sidebarOpen}
      closeSidebar={closeSidebar}
      position="right"
      className="sidebar-size"
    >
      <Sidebar.Header action={closeSidebar} actionText="Close">
        <h3>{!editItem.id ? 'Create new user' : `Edit ${type}`}</h3>
      </Sidebar.Header>
      <div className="p-4">
        {type === 'key' && rotateSecretSection()}
        {type === 'user' && !editItem.id && newUserUsernameSection()}
        {type === 'user' && editItem.id && editUserUsernameSection()}

        <Input
          label="Notes"
          value={noteEdit}
          onChange={e => setNoteEdit(e.target.value)}
        />
      </div>
      <Sidebar.Footer className="sidebar-size">
        {error && <Alert color="warning">{error}</Alert>}
        <Button
          disabled={editItem.id ? !noteEdit : !userEmail.match(/\S+@\S+\.\S+/)}
          className="bg-primary300 w-100 text-white"
          onClick={e => {
            save(e, userEmail || editItem.id, noteEdit)
            closeSidebar()
          }}
        >
          Save changes
        </Button>
      </Sidebar.Footer>
    </Sidebar>
  )
}

EditSidebar.propTypes = propTypes
EditSidebar.defaultProps = defaultProps
export default EditSidebar
