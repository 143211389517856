import React, { Component } from 'react'
import api from 'api/docs'
import { withAuth } from '../../state/auth'
import { NotAuthenticatedError, ForbiddenError, gateway } from '../../service/util'
import './DocDisplay.css'

class DocDisplay extends Component {
  state = {
    doc: '',
    serviceId: '',
    toc: true,
  }

  componentDidMount = () => {
    this.loadDoc()
  }

  componentDidUpdate = prevProps => {
    if (
      !prevProps ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.loadDoc()
    }
  }

  loadDoc = () => {
    const service = `${this.props.match.params.hostedAt}`
    const path = `/${this.props.match.params[0]}`

    api
      .readDocs(service, path)
      .then(doc => this.rewriteLinks(doc, this.props.match.params.id))
      .then(doc => this.checkForTOC(doc))
      .then(doc => this.rewriteImages(doc, service, path))
      .then(doc =>
        this.setState({ doc: doc.innerHTML, serviceId: this.props.match.id }),
      )
      .then(() => this.props.auth.setAuthenticated(true))
      .catch(err => {
        if (err instanceof NotAuthenticatedError) {
          this.props.history.push('/login?redirect=' + this.props.location.pathname)
        } else if (err instanceof ForbiddenError) {
          this.props.history.push('/forbidden')
        } else {
          console.log(err)
        }
      })
  }

  rewriteLinks = (doc, serviceId) => {
    // Links that are relative to the host should be relative to the
    // documented host, not the documentation host.
    doc.querySelectorAll('a[href]').forEach(link => {
      const href = link.getAttribute('href')
      if (href.startsWith('/') || href.startsWith('../')) {
        link.setAttribute('href', gateway(serviceId, href))
      }
    })
    return doc
  }

  checkForTOC = doc => {
    const toc = doc.querySelector('div#toc')
    this.setState({ toc: !!toc })
    return doc
  }

  rewriteImages = (doc, service, path) => {
    // Rewrite these to service links
    doc.querySelectorAll('img').forEach(node => {
      const src = node.getAttribute('src')
      node.setAttribute('src', new URL(src, gateway(service, path)).href)
    })
    return doc
  }

  render = () => {
    const { doc, toc } = this.state
    return (
      <div
        id="display-container"
        className={
          toc ? 'DocDisplay-container' : 'DocDisplay-container no-toc-container'
        }
        dangerouslySetInnerHTML={{ __html: doc }}
      />
    )
  }
}

export default withAuth(DocDisplay)
