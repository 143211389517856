import React, { Component } from 'react'
import { string } from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { Alert } from '@hsq/razzle-dazzle'
import api from 'api/docs'

import './DocsScreen.css'

class DocLinks extends Component {
  static propTypes = {
    doc: string.isRequired,
  }

  render = () => {
    const { doc } = this.props

    let linkDocs = <span> </span>
    const hostedAt = doc.hostedAt || doc.id
    const id = doc.id
    if (doc.docPath) {
      linkDocs = (
        <Link
          className="btn btn-secondary"
          to={`/docs/${id}/${hostedAt}${doc.docPath}`}
          data-test="doc-button"
        >
          Documentation
        </Link>
      )
    }
    let linkSwagger = <span> </span>
    if (doc.swaggerPath) {
      linkSwagger = (
        <Link
          className="btn btn-secondary btn_swagger"
          to={`/swagger/${id}/${hostedAt}${doc.swaggerPath}`}
          data-test="swagger-button"
        >
          Swagger
        </Link>
      )
    }

    return (
      <span>
        {linkDocs} {linkSwagger}
      </span>
    )
  }
}

class DocsScreen extends Component {
  state = {
    docs: [],
    error: '',
  }
  componentDidMount = () => {
    api.fetchDocs().then(({ docs, error }) => {
      if (docs) this.setState({ docs })
      else this.setState({ error })
    })
  }

  render = () => {
    const { docs, error } = this.state
    return (
      <div>
        <header className="masthead text-white bg-primary titles">
          <div className="masthead-content container">
            <div>
              <h1 className="display-1 mb-0 text-center">HealthSparq APIs</h1>
            </div>
          </div>
        </header>
        <div className="bg-gray13 pt-5 pb-5">
          <div className="container pb-3">
            {error && <Alert color="warning">{error}</Alert>}
            {docs &&
              docs.map(d => (
                <div key={d.title} className="card DocsScreen-service mb-3">
                  <div className="card-body">
                    <h2>{d.title}</h2>
                    <p>{d.desc}</p>
                    <DocLinks doc={d} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(DocsScreen)
