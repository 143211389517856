import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { shape } from 'prop-types'
import { Button, Input } from '@hsq/razzle-dazzle'
import loginApi from 'api/login'

import { component } from 'component/FirstTimeLoginScreen/FirstTimeLoginScreen.module.scss'

class ResetPassword extends Component {
  static propTypes = {
    history: shape().isRequired,
  }

  state = {
    error: '',
    username: '',
  }

  forgotPassword = () => {
    const { history } = this.props
    const { username } = this.state
    loginApi.forgotPassword(username).then(({ status, error }) => {
      if (error) this.setState({ error })
      else history.push('/reset-password')
    })
  }

  render() {
    const { error, username } = this.state

    return (
      <div className="w-100">
        <div
          className={`${component} title py-5 mb-4 d-flex justify-content-center`}
        >
          <h1 className="display-3 col-6">Forgot your password?</h1>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-start col-6 mx-auto mt-5">
          Enter your email address and we'll send you a temporary code to reset your
          password.
          <Input
            value={username}
            onChange={e => this.setState({ username: e.target.value })}
          />
          {error && <div className="invalid-feedback">{error}</div>}
          <Button
            className="mt-4"
            color="primary"
            onClick={this.forgotPassword}
            disabled={!username.match(/\S+@\S+\.\S+/)}
          >
            Reset password
          </Button>
        </div>
      </div>
    )
  }
}

export default withRouter(ResetPassword)
