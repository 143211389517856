import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { withAuth } from 'state/auth.js'

const Breadcrumbs = () => {
  const location = useLocation()
  const route = location.pathname.slice(1).split('/')[0]

  const title = route === 'token-manager' ? 'Key Management' : 'User Management'

  return (
    <ol className="breadcrumb bg-white sidebar-margin">
      <li className="breadcrumb-item">
        <Link to="/">Home</Link>
      </li>
      <li className="breadcrumb-item font-weight-bold">
        <Link to={`/${route}`}>{title}</Link>
      </li>
    </ol>
  )
}

export default withAuth(Breadcrumbs)
