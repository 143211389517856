import { decode } from 'jsonwebtoken'

// NOTE: These are very simple cookie utility methods. If we need more complex
// handlers we should just use the `js-cookie` package.

/**
 * Set a cookie by name with passed value.
 * @param {string} name
 * @param {string} value
 */
export function setCookie(name, value) {
  document.cookie = `${name}=${value}; path=/`
}

/**
 * Get a cookie by name
 * @param {string} name
 */
export function getCookie(name) {
  const matchingCookie = document.cookie.split(';').filter(c => {
    const cookie = c.replace(/\s*/, '')
    return cookie.includes(`${name}=`)
  })

  return matchingCookie.length
    ? matchingCookie[0].slice(matchingCookie[0].indexOf('=') + 1)
    : null
}

/**
 * Delete a cookie by setting its expiration date to beginning of time.
 * @param {string} name
 */
export function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
}

// Decode a JWT to get user roles
export function getUserRoles() {
  const token = getCookie('Token')
  if (token) return decode(token).roles
}
