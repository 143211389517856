import React from 'react'

const ForbiddenScreen = props => (
  <div style={{ marginTop: '80px' }}>
    <h1>Forbidden</h1>
    <p>Sorry, you do not have access to this resource!</p>
  </div>
)

export default ForbiddenScreen
