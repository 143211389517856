import { gateway } from 'service/util'

const service = 'healthsparq-developer-app-service'

const fetchDocs = () => {
  return fetch(gateway(service, '/v1/docs'), {
    credentials: 'include',
  })
    .then(res => res.json())
    .then(res => {
      if (res.error === 'Unauthorized') {
        return fetchPublicDocs()
      }
      return res
    })
}

const fetchPublicDocs = () => {
  return fetch(gateway(service, '/v1/public/docs')).then(res => res.json())
}

const readDocs = (service, path) => {
  return fetch(gateway(service, path), {
    credentials: 'include',
  })
    .then(res => res.text())
    .then(res => {
      const everything = document.createElement('div')
      everything.innerHTML = res
      const useful = document.createElement('div')

      const header = everything.querySelector('#header')
      if (header) {
        useful.appendChild(header)
      }

      const content = everything.querySelector('#content')
      if (content) {
        useful.appendChild(content)
      }

      const footnotes = everything.querySelector('#footnotes')
      if (footnotes) {
        useful.appendChild(footnotes)
      }

      if (!header && !content && !footnotes) {
        try {
          JSON.parse(res)
          const codeNode = document.createElement('code')
          const preNode = document.createElement('pre')
          codeNode.classList.add('text-white')
          codeNode.innerHTML = res.replaceAll('<', '&lt;').replaceAll('>', '&gt;')
          preNode.appendChild(codeNode)
          useful.appendChild(preNode)
        } catch (e) {}
      }
      return useful
    })
}

export default {
  fetchDocs,
  fetchPublicDocs,
  readDocs,
}
