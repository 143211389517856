import React from 'react'
import { arrayOf, func, shape } from 'prop-types'
import { ToastNotification } from '@hsq/razzle-dazzle'

import { component } from './Notifications.module.scss'

const propTypes = {
  removeToastNotification: func.isRequired,
  toastNotifications: arrayOf(shape({})).isRequired,
}

const Notifications = ({ removeToastNotification, toastNotifications }) => (
  <div className={`${component} d-flex flex-column align-items-end`}>
    {toastNotifications.map(({ id, body, ...rest }) => (
      <ToastNotification
        className="mb-3"
        key={id}
        body={body}
        onDismiss={() => removeToastNotification(id)}
        data-test="toast-notification"
        {...rest}
      />
    ))}
  </div>
)

Notifications.propTypes = propTypes
export default Notifications
