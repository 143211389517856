import React from 'react'
import { withAuth } from '../../state/auth.js'
import { Button } from '@hsq/razzle-dazzle'

import '../DocsScreen/DocsScreen.css'

class IndexScreenv2 extends React.Component {
  render() {
    return (
      <div>
        <header className="masthead text-white bg-primary titles">
          <div className="masthead-content">
            <div className="container text-center">
              <h1 className="display-1">HealthSparq Developer Hub</h1>
              <h2 className="display-4 mb-0 font-weight-light">
                Access the data to help people make smarter health care choices.
              </h2>
              <Button
                outline
                color="light"
                className="mt-5"
                onClick={() => this.props.history.push('/login')}
              >
                Login
              </Button>
            </div>
          </div>
        </header>

        <section>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-lg-2">
                <div className="p-5">
                  <img
                    src="/images/code-editor-64.svg"
                    className="w-75"
                    alt="Browser Window Web Appplication Documentation Layout"
                  />
                </div>
              </div>
              <div className="col-lg-6 order-lg-1">
                <div className="p-5">
                  <h2 className="display-3">How tos and guides...</h2>
                  <p className="lead text-muted">
                    Access to our API documentation means you can see the full
                    picture. Getting started guides give you a foothold, then once
                    you're rolling, Swagger docs can help you build custom health
                    care applications quickly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="p-5">
                  <img
                    src="/images/window-dev-64.svg"
                    className="w-75"
                    alt="Browser Window Icon with Code Caret"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-5">
                  <h2 className="display-3">Lots of code snippets.</h2>
                  <p className="lead text-muted">
                    When you're looking to build something, having runnable code
                    makes it easier. We spend time providing formatted code blocks
                    that you can copy and paste.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-lg-2">
                <div className="p-5">
                  <img
                    src="/images/code-64.svg"
                    className="w-75"
                    alt="Code Markup Tag Icon"
                  />
                </div>
              </div>
              <div className="col-lg-6 order-lg-1">
                <div className="p-5">
                  <h2 className="display-3">Let there be code!</h2>
                  <p className="lead text-muted">
                    We understand what it takes to code because the HealthSparq
                    Developer Hub was built by developers. We write code every
                    single day to make it easier for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withAuth(IndexScreenv2)
