import qs from 'qs'
import statusCodes from './statusCodes'

/**
 * Safe fetch actually handles two responsibilities:
 * 1. Valdiation of _basic_ fetch requirements like method and credentials
 * 2. Wrapper that formats all responses to match our standard data format with a
 *    catch handler, just in case.
 *
 * A safe fetch response will always return either data or an error field along with
 * the original response object.
 *
 * ```javascript
 * const safeFetchReturn = { ?data, ?error, response }
 * ```
 *
 * It's also possible to pass a set of override options as the third argument that
 * will not be modified in any way.
 * @param {string} url Request url
 * @param {Object} options Fetch options applied to request.
 * @param {Object} options.data Data for post request.
 * @param {Object} overrideInit Escape hatch that allows exact options to be
 * passed that will not be altered in any way.
 */
export default (url, { data, params, ...opts } = {}, overrideInit) => {
  // ℹ️ Fetch uses the term 'init' for configurations ¯\_(ツ)_/¯
  const init = {
    // By default use get and include cookies for requests
    method: 'GET',
    credentials: 'include',
    // spread passed opts to override defaults
    ...opts,
  }
  let response

  // Ensure we follow fetch method requirements
  init.method = init.method.toUpperCase()
  // Handle preparing POST request data and headers
  if (data) {
    try {
      // Fetch looks for a body for POST requests
      init.body = JSON.stringify(data)
      // Content type headers are not specified automatically
      init.headers = new Headers({
        'Content-Type': 'application/json',
      })
    } catch (ex) {
      /* eslint-disable prefer-promise-reject-errors */
      return Promise.reject({ error: 'Failed to stringify data' })
    }
  }

  const requestURL = params ? `${url}?${qs.stringify(params)}` : url
  return fetch(requestURL, overrideInit || init)
    .then(res => {
      response = res
      return res.json()
    })
    .then(json => {
      const normalizedResponse = { response }
      if (response.ok) {
        normalizedResponse.data = json
      } else {
        normalizedResponse.error =
          json.message ||
          json.explanation || // Preference-service closure error details
          json.error ||
          json.ERROR ||
          (json.body && json.body.message) || // Translation-service error format
          json.cause || // image uploader
          `${response.status}: ${statusCodes[response.status]}` // FINAL FALLBACK -- Just show the status
      }

      return normalizedResponse
    })
    .catch(err => {
      let error = err

      // If the error here is a result of trying to parse JSON out of a
      // janky HTML or text response body, make sure we don't break the
      // entire site and instead call out the improper response format
      if (err && err.message) {
        error = `Error: ${
          err.message
        }. This usually means that an endpoint has returned a non-JSON response body.`
      }
      return { error, response }
    })
}
