import { connect } from 'react-redux'

import {
  actionCreators as displayActionCreators,
  selectors as displaySelectors,
} from 'dux/display'

import Notifications from './Notifications.jsx'

const mapStateToProps = store => ({
  toastNotifications: displaySelectors.getToastNotifications(store),
})

const mapDispatchToProps = {
  removeToastNotification: displayActionCreators.removeToastNotification,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications)
